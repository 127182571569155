













































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  data() {
    return {
      valid: false,
      dataLoading: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      data: {},
    };
  },
  async created() {
    this.dataLoading = true;
    this.data = await coreApiClient.callRaw("auth", "getProfile");
    this.dataLoading = false;
  },
  methods: {
    async submit() {
      this.error = "";
      this.loading = true;
      try {
        const ok = await coreApiClient.callRaw("auth", "updateProfile", undefined, this.data);
        if (ok) {
          alert("Profile Updated Successfully");
          location.reload();
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
});
